import React, { useState, useEffect } from "react"
import { FilterListItem, Selected } from "./style.js"

const FilterItem = React.memo(
  ({
    filters,
    setFilters,
    areFiltersCleared,
    label,
    clearFilters,
    setCurrentPage,
  }) => {
    const [isSelected, setSelected] = useState(false)

    useEffect(() => {
      areFiltersCleared && setSelected(false)
      isSelected && setFilters(filters => [...filters, `categories:${label}`])
    }, [isSelected, areFiltersCleared, label, setFilters])

    const filterSelected = () => {
      setSelected(!isSelected)
      areFiltersCleared && clearFilters(false)

      if (isSelected) {
        const updatedFilters = filters.filter(function(value) {
          return value !== `categories:${label}`
        })

        setFilters([...updatedFilters])
      }
    }

    useEffect(() => {
      if (filters.includes(`categories:${label}`)) {
        setSelected(true)
      } else {
        setSelected(false)
      }
    }, [filters, label])

    return (
      <FilterListItem isSelected={isSelected}>
        <Selected
          isSelected={isSelected}
          onClick={() => {
            filterSelected()
            setCurrentPage(1)
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </Selected>
      </FilterListItem>
    )
  }
  // (prevProps, nextProps) => {
  //   if (
  //     prevProps.filters !== nextProps.filters &&
  //     !nextProps.areFiltersCleared
  //   ) {
  //     return true
  //   }
  // }
)

export default FilterItem
