import React from 'react';
import Layout from '../components/layout';
import SearchPage from '../components/SearchPage/SearchPage';
import SEO from '../components/seo';

const Search = () => {
	return (
		<Layout search>
			<SEO title="Search" url={`${process.env.GATSBY_WEBSITE_DOMAIN}/search/`} />

			<SearchPage />
		</Layout>
	);
};

export default Search;
