import React, { useRef, useState, useEffect, useCallback } from "react"
// import usePrevious from '../../hooks/usePrevious';
import { ReactComponent as SearchRemove } from "../../images/search-remove.svg"
import {
  SearchPageContainer,
  SearchBarContainer,
  InputContainer,
  Input,
  CloseSearch,
  Number,
  BottomPagination,
} from "./style.js"
import history from "../../history"

import { AdvertisementWrapper } from "../AdvertisementWrapper"
import { GoogleAd } from "../GoogleAd"
import { useStaticQuery, graphql } from "gatsby"
import CategoryFilter from "./CategoryFilter"
import { ResultsGrid } from "../TeaserGrids/index.js"
import algoliasearch from "algoliasearch"
import { Pagination } from "../Pagination/index.js"

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME)

const SearchPage = props => {
  const InitialPosts = () => {
    const { wpgraphql } = useStaticQuery(graphql`
      query posts {
        wpgraphql {
          posts(first: 30) {
            nodes {
              title
              colourFeatureImage
              link
              id
              slug
              categories {
                nodes {
                  name
                  slug
                  link
                }
              }
              excerpt
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
    `)

    return wpgraphql.posts.nodes
  }

  const initialPosts = InitialPosts()

  let queryString
  let filtersParam = []
  let pageParam
  const isFirstRun = useRef(true)

  if (typeof window !== `undefined`) {
    const urlParams = new URLSearchParams(window.location.search)
    queryString = urlParams.get("q")
    pageParam = urlParams.get("p")
    filtersParam = urlParams.getAll("f")
    filtersParam = filtersParam.map(filter => "categories:" + filter)
  }

  //input handling
  const inputRef = useRef()
  const [query, setQuery] = useState(queryString || "")
  const [queryExists, setQueryExists] = useState(false)

  //state
  const postsPerPage = 30
  const [posts, setPosts] = useState(initialPosts)
  const [totalPosts, setTotalPosts] = useState(300)
  const [currentPage, setCurrentPage] = useState(1)

  const [filters, setFilters] = useState(filtersParam)
  const [areFiltersCleared, setFiltersCleared] = useState(false)

  const lastPage = Math.ceil(totalPosts / postsPerPage)

  const escHandler = event => {
    if (event.keyCode === 27) {
      setQuery("")
    }
  }

  const checkEnter = e => {
    if (e.key === "Enter") {
      inputRef.current.blur()
    }
  }

  /**** ALGOLIA DEV ****/

  const emptyQuery = !query.length && !filters.length && currentPage === 1

  const pushToHistory = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("p", currentPage)
    urlParams.set("q", query || "")

    let filtersMassage = []

    if (filters) {
      const noDuplicateFilters = [...new Set(filters)]
      urlParams.delete("f")
      filtersMassage = noDuplicateFilters.map(filter => {
        urlParams.append("f", filter.replace("categories:", ""))
      })
    }

    history.replace({
      pathname: `${window.location.pathname}` + "?" + urlParams.toString(),
      state: { page: currentPage, query: query, filters: filtersMassage },
    })
  }, [query, currentPage, filters])

  const algoliaSearch = useCallback(() => {
    index.search(
      {
        query: query,
        page: currentPage - 1,
        facetFilters: [filters],
      },
      (err, data = {}) => {
        // console.log("err:", err)
        // console.log("data:", data)
        if (err) throw err
        setPosts(data.hits)
        setTotalPosts(data.nbHits)
        // console.log(data)
        // console.log("algolia ran")

        if (filters.length || query.length) {
          setQueryExists(true)
        } else {
          setQueryExists(false)
        }
      }
    )
  }, [query, currentPage, filters])

  /**** USE EFFECTS ***/

  useEffect(() => {
    document.addEventListener("keydown", escHandler)
    return function cleanup() {
      window.removeEventListener("keydown", escHandler)
    }
  }, [])

  useEffect(() => {
    areFiltersCleared && setFilters([])
  }, [areFiltersCleared])

  useEffect(() => {
    if (
      !queryString &&
      (!pageParam || pageParam === 1) &&
      !filtersParam.length
    ) {
      if (isFirstRun.current) {
        console.log("first")
        isFirstRun.current = false
        return
      }
    }

    algoliaSearch()
    pushToHistory()
  }, [algoliaSearch, queryString, emptyQuery])

  const noResults = totalPosts === 0

  return (
    <SearchPageContainer>
      <SearchBarContainer>
        <InputContainer>
          <Input
            autoFocus
            spellCheck={false}
            ref={inputRef}
            type="text"
            placeholder="Search"
            onChange={e => {
              setQuery(e.target.value)
              setCurrentPage(1)
            }}
            value={query}
            data-hj-whitelist
            onKeyPress={checkEnter.bind(this)}
          />
          <CloseSearch queryExists={query.length} onClick={() => setQuery("")}>
            <SearchRemove className="search-remove" />
          </CloseSearch>
        </InputContainer>
        <Number>
          {emptyQuery ? (
            "Type anything or use the category filters to find some inspiration."
          ) : queryExists ? (
            <p>
              {noResults ? "No" : <span>{totalPosts}</span>} result
              {totalPosts === 1 ? null : "s"} found.
              {noResults ? (
                <> Try a different query or choose more filters.</>
              ) : (
                <>
                  {" "}
                  Showing page{" "}
                  <span> {totalPosts === 0 ? 0 : currentPage}</span> of{" "}
                  <span>{lastPage}</span>.
                </>
              )}
            </p>
          ) : (
            "Type anything or use the category filters to find some inspiration."
          )}
        </Number>
        <CategoryFilter
          filters={filters}
          setFilters={setFilters}
          setFiltersCleared={setFiltersCleared}
          areFiltersCleared={areFiltersCleared}
          setCurrentPage={setCurrentPage}
        />
      </SearchBarContainer>
      <ResultsGrid posts={posts} search />

      <BottomPagination>
        <Pagination
          filters={filters}
          query={query}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          lastPage={lastPage}
          totalPosts={totalPosts}
          bottom
          noResults={noResults}
          scrollId="header"
          setFilters={setFilters}
          setQuery={setQuery}
        />
      </BottomPagination>
      {posts.length > 6 && (
        <AdvertisementWrapper horizontal>
          <GoogleAd
            className="horizontal"
            client="ca-pub-4461681428787239"
            slots={["6661339013", "1135230920", "5949956794"]}
          />
        </AdvertisementWrapper>
      )}
    </SearchPageContainer>
  )
}

export default SearchPage
