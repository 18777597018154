import React, { useState } from "react"
import { CategoriesData } from "../../hooks/categories-data.js"

import {
  Container,
  FilterContainer,
  TriggerContainer,
  DropDownContainer,
  FiltersList,
  ClearAndClose,
  Overlay,
  ClearButton,
  BoxShadow,
  CloseButton,
  CatFilterTrigger,
} from "./style.js"
import { ReactComponent as SearchRemove } from "../../images/search-remove.svg"
import { ReactComponent as CatFilterIcon } from "../../images/canvas.svg"

import { ReactComponent as Arrow } from "../../images/down-arrow.svg"
import FilterItem from "./FilterItem"

const CategoryFilter = React.memo(
  ({
    filters,
    setFilters,
    areFiltersCleared,
    setFiltersCleared,
    setCurrentPage,
  }) => {
    const [isFiltersShown, setFiltersShown] = useState(false)

    const hasFilters = filters.length > 0
    const categories = CategoriesData()

    const clearFilters = value => {
      setFiltersCleared(value)
    }

    return (
      <Container>
        <Overlay isFiltersShown={isFiltersShown} />
        <CatFilterTrigger
          hasFilters={hasFilters}
          isFiltersShown={isFiltersShown}
          onClick={() => setFiltersShown(!isFiltersShown)}
        >
          <CatFilterIcon />
          <p>Filter by category</p>
          <Arrow />
        </CatFilterTrigger>

        <FilterContainer isFiltersShown={isFiltersShown}>
          <TriggerContainer
            isFiltersShown={isFiltersShown}
            onClick={() => setFiltersShown(!isFiltersShown)}
          >
            <div>
              <span>Categories</span>
              {hasFilters && (
                <span className="selected">{filters.length} selected</span>
              )}
            </div>

            <Arrow />
          </TriggerContainer>
          <DropDownContainer isFiltersShown={isFiltersShown}>
            <FiltersList isFiltersShown={isFiltersShown}>
              {categories.map((item, index) => (
                <FilterItem
                  filters={filters}
                  setFilters={setFilters}
                  clearFilters={clearFilters}
                  areFiltersCleared={areFiltersCleared}
                  key={index}
									label={item.name}
									setCurrentPage={setCurrentPage}
                />
              ))}
            </FiltersList>
            <ClearAndClose>
              <ClearButton onClick={() => clearFilters(true)}>
                <SearchRemove />
                <span>clear filters</span>
              </ClearButton>
              <CloseButton onClick={() => setFiltersShown(!isFiltersShown)}>
                Done
              </CloseButton>
            </ClearAndClose>
          </DropDownContainer>
        </FilterContainer>
        <BoxShadow isFiltersShown={isFiltersShown} />
      </Container>
    )
  }
)

export default CategoryFilter
